import React from 'react'
import { graphql } from 'gatsby'
import { object } from 'prop-types'
import { MegaBestPicks } from '@jelly/templates'
import { SECTION_BEST_PICKS } from '@jelly/templates/lib/Category/constants'

const DEFAULT_CHANNEL = {
	primary: {
		name: 'Business Basics',
		slug: 'business-basics',
	},
	sub: {
		name: 'Starting a Business',
		slug: 'starting-a-business',
	},
}

const Index = ({ data: { props }, pageContext: { canonical } }) => {
	// Take the path from thumbnail and assign it to meta.ogImage to fix the issue with social sharing for this template type.
	props.meta.ogImage = props.thumbnail.path
	props.meta.canonical = canonical
	props.canonical = canonical
	props.sections = props.sections.map((section) => {
		if (section.type === SECTION_BEST_PICKS) {
			section.bestpicks = section.bestpicks.filter(({ review }) => review !== null)
		}
		return section
	})

	props.channels = !!props.channels ? props.channels : DEFAULT_CHANNEL
	props.breadcrumbs = !!props.breadcrumbs ? props.breadcrumbs : props.channels

	return <MegaBestPicks {...props} />
}

Index.propTypes = { data: object, pageContext: object }

export default Index
export const GatsbyQuery = graphql`
	query mega($slug: String!, $preview: Boolean) {
		# We always need to return props to ve injected on the component
		props: category(slug: $slug, preview: $preview, cache: false) {
			adsense
			adsenseMobile
			byLine
			canonical
			cardDescription
			created
			docType
			hasVwo
			displayModified
			displayModifiedText
			published
			slug
			status
			stream
			structuredData
			subtitle
			template
			title
			type
			widget: section(type: SectionWidget) {
				... on SectionWidget {
					widget {
						widgetId
					}
				}
			}
			subtitle
			guid
			sections {
				__typename
				id
				isInToc
				type
				... on SectionReviews {
					title
					reviews {
						title
					}
				}
				... on SectionWidget {
					title
					widget {
						widgetId
					}
				}
				... on SectionContainer {
					title
					container {
						isBuyingGuide
						html
					}
				}
				... on SectionBuyingGuide {
					title
					containers {
						title
						html
					}
				}
				... on SectionMoreCoverage {
					isActive
					title
					isActive
					articles {
						title
						canonical
						author {
							displayName
						}
						thumbnail {
							path
						}
					}
				}
				... on SectionOurApproach {
					isActive
					ourapproach {
						isClonable
						servicesConsidered
						servicesTested
						servicesSelected
						linkText
						linkUrl
					}
				}
				... on SectionServices {
					title
					services {
						canonical
						id
						description
						serviceName: displayName
						pricing {
							...pricingFields
						}
						thumbnail {
							path
						}
					}
				}
				... on SectionBestPicks {
					megaBestPicksTopCardLimit
					bestpicks {
						bullets
						description
						descriptionTest
						fullTitle
						isFeaturedBestPick
						isJumpLink
						tabTitle
						review {
							displayName
							canonical
							id
							url
							review {
								pros
								cons
								fullReviewTest
								fullReview
							}
							pricing(type: "categories") {
								...pricingFields
							}
							thumbnail {
								path
							}
						}
					}
				}
			}
			tocElements {
				title
				id
			}
			author {
				id
				name: displayName
				bio
				thumbnail
				status
				type
				user {
					slug
					imagePath
				}
				title
				image
				social {
					facebook
					linkedin
					twitter
					website
				}
			}
			breadcrumbs {
				primary {
					name
					slug
				}
				sub {
					name
					slug
				}
			}
			channels {
				primary {
					name
					slug
				}
				sub {
					name
					slug
				}
			}
			thumbnail {
				path
				caption
				isBlurred
			}
			image
			meta {
				robots
				description
				title
			}
		}
	}
`
